<template>
  <div>
    <!-- Parcelle -->
    <h4 class="subtitle-form">{{ $t("harvestStorage.list.cropField") }}</h4>
    <Dropdown
      ref="dropdown"
      class="shape-custom-select mb-2"
      label="name_surface_cropName"
      :options="cropFieldsUpdated"
      :value="this.cropfieldSelected"
      @input="(selectedInput) => checkCropField(selectedInput)"
      required
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Dropdown from "@/components/harvestStorage/edit/Dropdown.vue";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import LoadFormPartMixin from "@/mixins/LoadFormPartMixin";
import CropfieldModule from "@/store/modules/cropfield";

export default {
  mixins: [DetailUpdateMixin, LoadFormPartMixin],
  data() {
    return {
      cropfieldSelected: {},
      cropFieldsUpdated: [],
      index: "",
      loadComponent: false,
    };
  },
  components: {
    Dropdown,
  },
  async mounted() {
    this.loadComponent = false;
    this.init();
  },

  async beforeCreate() {
    const store = this.$store;
    if (store && store.state) {
      if (!store.state["cropfield"]) {
        store.registerModule("cropfield", CropfieldModule);
      }
    }
  },
  methods: {
    init: async function() {
      await this.$store.dispatch("cropfield/fetchEntries", { filter: {} });
      this.getCropfieldsUpdated();
      if (this.$route.name === "harvestStorage.edit") {
        await this.$store.dispatch(this.storeEditAction, { activity: this.currentEntry.activity });
        await this.$store.dispatch(this.storeEditAction, { creator: this.currentEntry.creator });
      }
      if(this.$route.name.includes("edit")) {
        this.index = this.cropFieldsUpdated.findIndex((element) => element.id == this.currentEntry.cropField);
        if (this.index != -1) {
          this.cropfieldSelected = this.cropFieldsUpdated[this.index];
        }
      }
      if (this.$route.name === "harvestStorage.new") {
        await this.$store.dispatch(this.storeEditAction, { creator: this.user.username });
      }
      this.loadComponent = true;
    },

    /**
     * @description stocke la parcelle sélectionnée dans l'attribut cropfieldSelect
     */
    checkCropField(value) {
      this.cropfieldSelected = value;
    },

    /**
     * @description Renvoie un array pris de this.cropfields + surchargé avec un attribut 
     * name_surface_cropName qui va être util dans le DropDown
     */
    getCropfieldsUpdated() {
      this.cropFieldsUpdated = this.cropfields.map(obj=> ({
         ...obj, 
         name_surface_cropName: obj.name + " (" + obj.surface + " ha) " + obj.crop_name 
      }));
      return this.cropFieldsUpdated;
    },
    /**
     * Description: Confirme le chargement des données de ce composant.
     * @return {Boolean} Component loaded.
     */
    validateForm: function() {
      this.isValid = true;
      return this.loadComponent;
    },
  },
  computed: {
    ...mapGetters({
      currentHarvest: "harvestStorage/currentEntry",
      cropfields: "cropfield/entries",
      user: "user/user",
    }),
  },
  watch: {
    cropfieldSelected: {
      deep: true,
      handler: async function () {
        await this.$store.dispatch(this.storeEditAction, { crop_species_code: this.cropfieldSelected.crop_species_code });
        await this.$store.dispatch(this.storeEditAction, { cropField: this.cropfieldSelected.id });
        await this.$store.dispatch(this.storeEditAction, { crop: this.cropfieldSelected.crop });
      },
    },
  },
};
</script>

<style></style>
